import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Feed from "../components/feed";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = `${data.site.siteMetadata.title} | ${data.site.siteMetadata.author}`;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout>
        <SEO title={siteTitle} />
        <h1>Latest Podcast Episodes</h1>
        <Feed feed={posts} />
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "//blog//" } }
    ) {
      edges {
        node {
          ...Feed_item
        }
      }
    }
  }
`;
